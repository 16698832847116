<template>
  <!-- Success Modal -->
  <Modal size="sm">
    <template #body>
      <div class="flex flex-col items-center justify-center">
        <div class="flex mb-5 -space-x-4">
          <img class="w-11 h-11 rounded-full border-2 border-white" :src="jobDescription.employer.logo_url" alt="image" />
          <img class="w-10 h-10 rounded-full border-2 border-white" :src="userDetails.candidate.avatar" alt="image" />
        </div>
        <p class="text-brand-black text-opacity-70 text-2xl font-normal mb-2 font-cooper">Application Successful!</p>
        <p class="text-brand-black text-opacity-60 font-normal text-base text-center mb-4">
          We’ve sent your application to {{ jobDescription.employer.name }}. Monitor the application process in your
          <span class="text-brand-black"><router-link to="/applied">Applied</router-link></span> tab.
        </p>

        <Button label="Applied Jobs" color="primary" @click.stop="emits('close-modal')" medium />
      </div>
    </template>
  </Modal>
</template>
<script setup>
import Modal from '@/components/Modal.vue';
import Button from '@/components/Button.vue';

const props = defineProps({
  jobDescription: {
    type: Object,
    required: true,
  },
  userDetails: {
    type: Object,
    required: true,
  },
});

const emits = defineEmits(['close-modal']);
</script>
