<template>
  <!-- Apply Modal -->
  <Modal size="3xl" class="overflow-y-scroll">
    <template #header>
      <div class="flex items-center justify-between w-full">
        <div class="flex items-center w-4/6">
          <img :src="jobDescription.employer.logo_url" class="h-8 w-8 rounded-full object-cover mr-2" alt="companyLogo" />
          <p class="font-cooper font-normal lg:text-lg text-base text-brand-black/70 capitalize">Apply as a {{ role(jobDescription) }}</p>
        </div>
        <Close @click.stop="emits('close-modal')" class="cursor-pointer" />
      </div>
      <div class="w-full bg-gray-200 mx-0 overflow-hidden border border-gray-300 mt-2">
        <div class="bg-brand-primary text-xs leading-none py-1" style="width: 100%"></div>
      </div>
    </template>
    <template #body>
      <div class="talent-profile flex flex-col items-start">
        <div class="flex flex-row columns-2 w-full">
          <div class="">
            <img :src="talent.candidate.avatar" alt="profile" class="h-10 w-10 rounded-full object-cover mr-2" />
          </div>
          <div class="">
            <p class="font-bold text-sm m-0 capitalize">{{ talent.name }}</p>
            <p class="text-opacity-60 font-light text-xs order-last">{{ talent.candidate.role_title }}</p>
          </div>
        </div>
        <div class="w-full flex flex-col columns-1">
          <div class="flex items-center border-b py-4">
            <div class="w-1/3 text-[15px]">Linkedin</div>
            <div class="w-2/3 text-[15px] font-medium">
              {{ linkedin_url }}
            </div>
          </div>
          <div class="flex items-center border-b py-4">
            <div class="w-1/3 text-[15px]">Available</div>
            <div class="w-2/3 text-[15px] font-medium capitalize">
              <span class="bg-[#089115]/[.10] p-2 px-4 text-[#089115] text-[16px] font-bold rounded-lg"> Available </span>
            </div>
          </div>
          <div class="flex items-center border-b py-4">
            <div class="w-1/3 text-[15px]">Resume</div>
            <div class="w-2/3 text-[15px] font-medium">
              <div class="flex items-center">
                <img src="@/assets/icons/pdf.svg" class="mr-2" alt="" />
                <tam-button
                  :href="props.talent.candidate?.resume"
                  @click.prevent="resumeUrl"
                  class="text-[#8F00E6] text-[15px] font-semibold"
                  :label="resume_label"
                />
              </div>
            </div>
          </div>
          <div class="flex items-center border-b py-4">
            <div class="w-1/3 text-[15px]">Why should we hire you?</div>
            <div class="w-2/3 text-[15px] font-medium">
              {{ jobForm?.note }}
            </div>
          </div>
          <div class="flex items-center border-b py-4">
            <div class="w-1/3 text-[15px]">What is your notice Period?</div>
            <div class="w-2/3 text-[15px] font-medium">
              {{ jobForm.automated_interview_responses?.notice_period }}
            </div>
          </div>
          <div class="flex items-center border-b py-4">
            <div class="w-1/3 text-[15px]">What is your Salary Expectations? (Yearly)</div>
            <div class="w-2/3 text-[15px] font-medium capitalize">
              {{ jobForm.automated_interview_responses?.expected_salary }}
            </div>
          </div>
          <div class="flex items-center border-b py-4" v-for="(question, idx) in automated_interview_responses" :key="idx">
            <div class="w-1/3 text-[15px]">{{ question.key }}</div>
            <div class="w-2/3 text-[15px] font-medium">
              {{ question.value }}
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="container">
        <div class="sm:grid md:flex justify-between md:flex-row columns-1 md:columns-2">
          <div class="flex flex-row items-center justify-between md:space-x-32 mb-3 columns-1">
            <div class="grid grid-cols-1 justify-between md:space-x-2 mb-3 columns-1 text-brand-black">
              <p class="text-base font-bold mb-2">Hiring Contact</p>
              <div class="flex flex-row columns-2 w-full">
                <div class="w-1/3">
                  <img :src="hiring_img" alt="profile" class="h-8 w-8 rounded-full object-cover mr-2" />
                </div>
                <div class="w-2/3">
                  <p class="font-bold text-sm m-0 capitalize">{{ hiring_name }}</p>
                  <p class="text-opacity-60 font-light text-xs order-last" v-if="hiring_position">{{ hiring_position }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-row items-center">
            <tam-button
              label="Edit application"
              color="default"
              :outline="true"
              class="px-4 mr-5 border-brand-primary pre-btn"
              btnSize="w-full md:w-28"
              @click="emits('show-note')"
              :loading="isLoading"
              :disabled="disabled"
            />
            <tam-button
              :label="submit_content"
              color="primary"
              class="self-end"
              btnSize="w-full md:w-28"
              @click="emits('submit')"
              :disabled="isLoading"
            />
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>
<script setup>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import Close from '@/assets/icons/close.svg?inline';
import Modal from '@/components/Modal.vue';
import TamButton from '@/components/TamButton.vue';

const store = useStore();

const emits = defineEmits(['close-modal', 'show-note', 'submit']);
const props = defineProps({
  jobDescription: {
    type: Object,
    required: true,
  },
  talent: {
    type: Object,
    required: true,
  },
  linkedin_url: {
    type: String,
    required: true,
  },
  isLoading: {
    type: Boolean,
    required: true,
  },
  disabled: {
    type: Boolean,
    required: true,
  },
  submit_content: {
    type: String,
    required: true,
  },
});

const expected_salary = ref('');
const notice_period = ref('');

const role = (job) => job.role_title || job.title_name;

const automated_interview_responses = ref({});

const jobForm = ref({});

const resume_label = computed(() => `${props.talent?.name.split(' ').join('_')}_CV`);

const resumeUrl = () => {
  const url = props.talent.candidate.resume;
  const paths = url?.split('/');
  const key = paths[paths.length - 1];
  store.commit('global/setResumeUrl', { key, url });
  const href = `/file/${key}`;
  const a = document.createElement('a');
  a.setAttribute('href', href);
  a.setAttribute('target', '_blank');
  a.click();
};

const hiring_img = computed(() => {
  if (props.jobDescription.postedBy) return props.jobDescription.postedBy.avatar_url;
  return props.jobDescription.employer.logo_url;
});
const hiring_name = computed(() => {
  if (props.jobDescription.postedBy) return props.jobDescription.postedBy.name;
  return props.jobDescription.employer.name;
});

const hiring_position = computed(() => {
  if (props.jobDescription.postedBy) {
    const position = props.jobDescription.postedBy.position ? `${props.jobDescription.postedBy.position},` : '';
    return `${position} ${props.jobDescription.employer.name}`;
  }
  return null;
});

onMounted(async () => {
  jobForm.value = await store.getters['global/getJobForm'];
  automated_interview_responses.value = { ...jobForm.value.automated_interview_responses };
  expected_salary.value = automated_interview_responses.expected_salary;
  notice_period.value = automated_interview_responses.notice_period;
  delete automated_interview_responses.value.expected_salary;
  delete automated_interview_responses.value.notice_period;
  process.env.NODE_ENV === 'development' ? console.log(automated_interview_responses.value) : '';
});
</script>
<style lang="scss">
.text-brand {
  color: #f0386a !important;
}

.mr-5 {
  margin-right: 20px;
}

.h-12 {
  height: 48px;
}

.talent-profile {
  gap: 18px;
  align-self: stretch;
}
.pre-btn {
  @media screen and (min-width: 768px) {
    position: relative;
    top: 20px;
  }
}
</style>
