<template>
  <section>
    <div v-if="pageLoaded">
      <NavBody>
        <template #body>
          <div class="flex items-center mb-6 cursor-pointer" @click="$router.push('/jobs')">
            <Back />
            <p class="text-[#8B8B8B] ml-2">Back to Job Page</p>
          </div>
        </template>
      </NavBody>
      <div class="mx-auto lg:max-w-[80rem] z-20 flex lg:flex-row flex-col w-full px-4 lg:px-0">
        <div class="scrollbar-hide space-y-4 mb-[14rem] md:mb-0 flex lg:flex-row flex-col justify-between md:gap-6 gap-3">
          <div class="rounded-xl bg-white lg:py-8 py-4 lg:px-12 px-5 grow lg:w-3/5 overflow-scroll h-full scrollbar-hide">
            <div>
              <p class="lg:text-4xl text-2xl text-brand-primary capitalize font-albertBold">{{ role(jobDescription) }}</p>
              <div class="flex items-center py-3">
                <img :src="jobDescription.employer.logo_url" alt="" class="rounded w-10 h-10 mr-2" />
                <p class="text-[#1C1C1EB8] capitalize">{{ jobDescription.employer.name }}</p>
                <p class="w-5 h-5 px-2 text-[#1C1C1EB8] self-center">.</p>
                <p class="text-[#1C1C1EB8]" v-if="jobDescription.state?.country_code === 'NG'">Nigeria</p>
                <p class="text-[#1C1C1EB8]" v-else>{{ jobDescription.state?.country_code }}</p>
              </div>
              <p class="text-[#1C1C1EB8] ext-base font-normal">Posted on {{ moment(jobDescription.created_at).format('MMM Do YYYY') }}</p>
            </div>
            <div class="border rounded-lg border-[#1C1C1E1F] lg:p-4 p-2 flex items-center justify-between my-8">
              <div class="flex items-center">
                <img :src="jobDescription.postedBy.avatar_url" alt="image" class="rounded-full w-12 h-12 mr-4" />
                <span>
                  <p class="text-[#1C1C1E] font-albertSemiBold">{{ jobDescription.postedBy.name }}</p>
                  <p class="text-[#1C1C1E] text-xs capitalize">{{ jobDescription.postedBy.position }}</p>
                </span>
              </div>
              <button class="rounded-xl bg-brand-primary px-2.5 py-2 text-white font-albertSemiBold">Send Message</button>
            </div>
            <div class="mb-4">
              <p class="font-medium text-xl text-brand-black mb-2">About this Job</p>
              <div v-html="jobDescription?.description" class="description"></div>
            </div>
            <div>
              <p class="font-medium text-xl text-brand-black mb-2">Job Specifications</p>
              <ul class="text-brand-black/70 font-light list-inside list-disc">
                <li v-for="item in jobDescription?.skills" :key="item" class="capitalize">{{ item }}</li>
              </ul>
            </div>
          </div>
          <div class="rounded-xl bg-white lg:px-12 px-6 py-6 grow lg:w-2/5 overflow-scroll md:h-[70%] scrollbar-hide">
            <div class="bg-brand-primary -mx-12 px-12 py-10 mt-14 hidden lg:block">f</div>
            <div class="border border-[#DEDEDE] rounded-3xl md:p-6 p-4 lg:relative lg:top-[-9.5rem] bg-white">
              <!-- <p class="text-[#8F8F8F] text-center">Application closes Jan 24, 2024</p> -->
              <p class="py-4 text-[32px] font-albertSemiBold text-center">
                {{ jobDescription?.referral_bonus_currency + jobDescription?.referral_bonus || 'NGN0.00' }} <br />
                reward
              </p>
              <div class="space-y-4">
                <button
                  @click="
                    selectedJob = jobDescription.uuid;
                    showAddCandidateToJob = true;
                  "
                  class="rounded-xl bg-brand-primary py-3 px-6 text-white font-albertSemiBold w-full hover:text-brand-primary hover:bg-white border hover:border-[#DEDEDE]"
                >
                  Refer a candidate
                </button>
                <button
                  @click.stop="proceedToApply(jobDescription)"
                  class="rounded-xl bg-white border border-[#DEDEDE] py-3 px-6 text-brand-primary font-albertSemiBold w-full hover:text-white hover:bg-brand-primary"
                >
                  Apply for the role
                </button>
              </div>
            </div>
            <div class="lg:mt-[-7rem] mt-8">
              <div class="flex items-center mb-4">
                <Money />
                <p class="ml-1 font-albertSemiBold text-base lg:text-lg text-brand-primary">
                  <span class="text-sm">{{ jobDescription.salary_currency }}</span>
                  {{ formatNumber(jobDescription?.salary_min) || jobDescription?.salary_min?.formatted }} -
                  {{ formatNumber(jobDescription?.salary_max) || jobDescription?.salary_max?.formatted }}
                  <span class="font-normal text-base">/ {{ jobDescription.pay_period.split('_').join(' ') }}</span>
                </p>
              </div>
              <div class="flex flex-wrap gap-2 items-center snap-start w-full overflow-auto scrollbar-hide">
                <!-- <p class="font-albertSemiBold text-brand-primary text-lg">Must Have</p> -->
                <div v-for="item in jobDescription.must_have" :key="item">
                  <p class="bg-[#F1F1F1] rounded-3xl text-brand-primary text-sm px-4 py-2 capitalize whitespace-nowrap">{{ item }}</p>
                </div>
                <p class="bg-[#F1F1F1] rounded-3xl text-brand-primary text-sm px-4 py-2 capitalize whitespace-nowrap">
                  {{ jobDescription.level_of_experience.split('_').join(' ') }}
                </p>
                <p class="bg-[#F1F1F1] rounded-3xl text-brand-primary text-sm px-4 py-2 capitalize whitespace-nowrap">
                  {{ jobDescription.type.split('_').join(' ') }}
                </p>
                <p class="bg-[#F1F1F1] rounded-3xl text-brand-primary text-sm px-4 py-2 capitalize whitespace-nowrap">
                  {{ jobDescription.work_type }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Loading :is-loading="!pageLoaded" className="flex justify-center py-6 lg" />

    <apply-note v-if="showNoteModal" @show-question="proceedToQUestion" @close-modal="showNoteModal = false" :jobDescription="jobDescription" />
    <apply-questions
      v-if="showQuestionModal"
      @show-note="editApplication"
      @show-cv="proceedToCv"
      @close-modal="showQuestionModal = false"
      :jobDescription="jobDescription"
      :linkedin_url="linkedin_url"
    />
    <apply-cv
      v-if="showCvModal"
      @show-question="proceedToQUestion"
      @show-preview="proceedToPreview"
      @close-modal="showCvModal = false"
      :jobDescription="jobDescription"
      :talent="userDetails"
    />
    <apply-preview
      v-if="showPreviewModal"
      @submit="apply"
      @show-note="editApplication"
      @close-modal="showPreviewModal = false"
      :jobDescription="jobDescription"
      :talent="userDetails"
      :linkedin_url="linkedin_url"
      :submit_content="submit_content"
    />
    <apply-success
      v-if="showSuccessModal"
      :jobDescription="jobDescription"
      :userDetails="userDetails"
      :disabled="disabled"
      :isLoading="isLoading"
      @close-modal="$router.push('/applied')"
    />
    <add-candidate-to-job-dialog
      :is-active="showAddCandidateToJob"
      :jobId="jobDescription"
      @close="
        selectedJob = null;
        showAddCandidateToJob = false;
      "
    />
  </section>
</template>

<script setup>
import moment from 'moment';
import { ref, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { errorMessage, alert, formatNumber } from '@/utils/helper';
import AddCandidateToJobDialog from '@/components/job/AddCandidateToJob.vue';
import NavBody from '@/components/NavBody.vue';
import Back from '@/assets/icons/Arrowback.svg?inline';
import Calendar from '@/assets/icons/calendar.svg?inline';
import Tag from '@/assets/icons/tag.svg?inline';
import Pin from '@/assets/icons/pin.svg?inline';
import Money from '@/assets/icons/money.svg?inline';
import Button from '@/components/Button.vue';
import Loading from '@/components/Loading.vue';
import ApplyNote from '@/components/job/ApplyNote.vue';
import ApplyQuestions from '@/components/job/ApplyQuestions.vue';
import ApplyCv from '@/components/job/ApplyCv.vue';
import ApplyPreview from '@/components/job/ApplyPreview.vue';
import ApplySuccess from '@/components/job/ApplySuccess.vue';

const showNoteModal = ref(false);
const selectedJob = ref(null);
const showQuestionModal = ref(false);
const showCvModal = ref(false);
const showPreviewModal = ref(false);
const showSuccessModal = ref(false);
const showAddCandidateToJob = ref(false);
const store = useStore();
const route = useRoute();
const router = useRouter();
const jobDescription = ref(null);
const pageLoaded = ref(false);
const isLoading = ref(false);
const submit_content = ref('Submit application');

const userDetails = computed(() => store.getters['auth/userDetails']);

const disabled = computed(() => jobDescription.value?.status === 'closed');

const isClosed = computed(() => jobDescription.value?.status === 'closed');

const linkedin_url = computed(() => {
  const user = store.getters['auth/userDetails'];
  const social_links = user.candidate.social_links;
  if (!social_links) return '';
  const linkedin = social_links.filter((link) => link.platform == 'linkedin')[0];
  return linkedin ? linkedin.url : '';
});
const role = (job) => job.role_title || job.title_name;

const proceedToApply = (job) => {
  if (!userDetails.value.candidate.resume) {
    alert('Resume not found. Please upload your resume');
    return router.push('/profile?resume=1');
  }
  if (job.external_website_url) {
    return (window.location.href = job.external_website_url);
  }
  showNoteModal.value = true;
};
const proceedToQUestion = () => {
  showNoteModal.value = false;
  showCvModal.value = false;
  showQuestionModal.value = true;
};
const proceedToCv = () => {
  showNoteModal.value = false;
  showQuestionModal.value = false;
  showCvModal.value = true;
};
const proceedToPreview = () => {
  showNoteModal.value = false;
  showQuestionModal.value = false;
  showCvModal.value = false;
  showPreviewModal.value = true;
};

const editApplication = () => {
  showPreviewModal.value = false;
  showQuestionModal.value = false;
  showNoteModal.value = true;
};

const apply = async () => {
  try {
    isLoading.value = true;
    submit_content.value = 'Submitting...';
    let job = await store.getters['global/getJob'](route.params.id);
    if (!job) {
      //might be a first site visit for the browser
      await store.dispatch('global/getAllJobs');
      job = await store.getters['global/getJob'](route.params.id);
    }
    const jobForm = await store.getters['global/getJobForm'];
    await store.dispatch('global/applyToJob', { id: jobDescription.value?.uuid || job?.uuid, ...jobForm });
    showPreviewModal.value = false;
    showSuccessModal.value = true;
    submit_content.value = 'Submitted';
  } catch (error) {
    if (error.response && error.response.status === 422) {
      console.log(error.response.data.errors);
      // return error.response.data.message;
    }
    alert(errorMessage(error), 'error');
    isLoading.value = false;
    submit_content.value = 'Submit application';
  }
};

const getJob = async () => {
  try {
    jobDescription.value = await store.dispatch('global/getSingleJob', route.params.id);
    process.env.NODE_ENV === 'development' ? '' : '';
  } catch (error) {
    alert(errorMessage(error), 'error');
  } finally {
    pageLoaded.value = true;
  }
};

onMounted(async () => {
  getJob();
  store.commit('global/setJobForm', {});
  await store.dispatch('global/getEnums');
});
</script>

<style lang="scss" scoped>
.description::v-deep {
  line-height: 1.8rem;

  ul {
    list-style: disc;
    margin-left: 1rem;
    padding-left: 1rem;
  }
}
</style>
